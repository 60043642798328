<template>
  <div class="root">
    <div class="top" style="display: flex;padding: 20px;">
      <div style="width: 60%">
        <div class="title">【默认二维码】</div>
        <div ref="qrcode"></div>
        <div style="margin-top: 10px;margin-bottom: 20px;">卡密到期时间：
          <div style="margin-top: 20px;color: #4cae1b">{{ lostTime }}</div>
        </div>
        <span class="ws_href" @click="copyWs()">复制WS推广链接</span>
      </div>

      <div style="width: 100%;text-align: left;">
        <div class="title">【使用说明】</div>
        <div>
          1. 失效码：使码永久失效，无法引流
        </div>
        <div>
          2. 暂停引新粉：老用户可以继续扫码进入，但新用户无法再扫码进来
          此举可以提高码的耐用性
        </div>
        <div class="title" style="margin-top: 20px;">【默认码二维码控制】</div>
        <div class="controlDefaults" style="font-size: 14px;color: #888888">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="4">引粉设置</el-col>
            <el-col :span="22">
              <el-radio-group size="small" v-model="codeState">
                <el-radio-button label="1">启用引粉</el-radio-button>
                <el-radio-button label="0">停用引粉</el-radio-button>
                <el-radio-button label="2">禁引新粉</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <template v-if="loadUrlSuccess">
      <div style="text-align: left;padding: 0 20px;">
        <el-button size="mini" type="primary" @click="showEdit=true,editData={}">创建码</el-button>
      </div>
      <div style="padding: 20px;box-sizing: border-box;">
        <el-table size="mini" border :data="tableData">
          <el-table-column label="序号" width="60px" type="index" align="center"></el-table-column>
          <el-table-column label="所属域名" align="center" width="150px"></el-table-column>
          <el-table-column label="状态" align="center" width="80px">
            <template slot-scope="scope">
              {{getStateStr(scope.row.status)}}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" width="200px">
            <template slot-scope="scope">
              {{formatTime(scope.row.createTime)}}
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="450px">
            <el-button size="mini" type="warning">编辑</el-button>
            <el-button size="mini" type="primary">下载</el-button>
            <el-button size="mini" type="success">复制链接</el-button>
            <el-button size="mini" type="info">失效码</el-button>
            <el-button size="mini" type="danger">暂停引新粉</el-button>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <el-dialog :title="editData.id?'编辑':'新增'" :visible.sync="showEdit" width="400px">
      <el-row type="flex" :gutter="20" align="middle">
        <el-col :span="6">所属域名</el-col>
        <el-col :span="18">
          <el-select size="small" style="width: 100%" v-model="editData.hostId" value-key="id">
            <el-option :label="item.name" :value="item.id" v-for="item in hostUrls"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="20" align="middle" style="margin-top: 20px">
        <el-col :span="6">备注</el-col>
        <el-col :span="18">
          <el-input size="small" v-model="editData.remark"></el-input>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="showEdit=false">取消</el-button>
        <el-button size="small" type="primary" @click="confirmEdit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: "Qrcode",
  data() {
    return {
      editData:{},
      showEdit:false,
      codeState: 1,
      tableData: [],
      lostTime: "",
      loadUrlSuccess:false,
      hostUrls:[]
    }
  },
  mounted() {
    this.init();
    this.$eventBus.$on("loadUserInfoSuccess", () => {
      this.init();
    });
    this.loadUrls();
    this.fillData();
  },
  methods: {
    async copyWs(){
      const text=`${window.location.origin}/#/verify/${this.$store.state.userStore.userInfo.id}`;
      const textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.opacity = 0;
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$message({
        type: 'success',
        message:'链接已复制'
      })
    },
    getStateStr(str){
      switch (str){
        case 0:
          return "停用"
        case 1:
          return "正常"
        case 2:
          return "异常"
      }
    },
    formatTime(str){
      let date = new Date(str);
      return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}`
    },
    async fillData(){
      const res=await this.$http.post("/qrcode/list")
      this.tableData=res;
    },
    async confirmEdit(){
      if (this.editData.hostId==null){
        this.$message({
          type:"error",
          message:"请选择所属域名"
        })
        return;
      }
      const res=await this.$http.post("/qrcode/add",this.editData)
      if (!res){
        this.$alert("操作失败")
        return;
      }
      this.showEdit=false;
      this.fillData()
    },
    init() {
      this.lostTime = this.$store.state.userStore.userInfo.cardLostTime;
      if (!this.lostTime) {
        return;
      }
      this.createQrcode()
    },
    createQrcode() {
      let url = window.location.origin + `/#/talk/${this.$store.state.userStore.userInfo.id}`;
      QRCode.toCanvas(url, {
        width: 200,
        height: 200
      }, (err, canvas) => {
        this.$refs.qrcode.appendChild(canvas);
      });
    },
    async loadUrls() {
      const res=await this.$http.post("/host/list")
      this.hostUrls=res;
      this.loadUrlSuccess=this.hostUrls!=null&&this.hostUrls.length>0;
    }
  },

}
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: 900;
  color: #53a0e7;
  margin-bottom: 20px;
}
.ws_href{
  border-radius: 5px;
  color: #3885d6;
  cursor: pointer;
  background: #bfddfb;
  padding: 10px 20px;
  font-size: 14px;
}
</style>